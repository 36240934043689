import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/apple-touch-icon.png';
import styles from './footer.module.scss';
import { Container, Row, Col, Image, Navbar } from 'react-bootstrap';
import logoLetters from '../../images/dashbot_logo_w.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`${styles.footerArea} pt-5 text-md-left text-center position-relative overflow-hidden`}>
      <div className={`${styles.bgDecor} d-none d-md-block position-absolute opacity-0-5`}></div>
      <Container className={`${styles.container}`}>
        <Row>
          <Col md={6}>
            <a href="/" className={`${styles.logo} d-block hover-opacity-0-75`}>
              <Image fluid src={logo} alt="logo" className={`d-inline-flex mx-auto ml-md-0`} />
              <Image
                fluid
                className={`${styles.logoText} ${styles.logoLetters} ml-2 d-none d-md-inline`}
                src={logoLetters}
              />
            </a>
          </Col>
          <Col md={6} className={`d-none d-md-block`}>
            <ul
              className={`${styles.socialLink} list-unstyled d-flex justify-content-center justify-content-md-end pr-lg-4 pr-xl-0`}
            >
              <li className={`${styles.menuItem}`}>
                <a href="https://www.facebook.com/dashbot.io" className="d-block" target="_blank" rel="noreferrer">
                  <i className="bx bxl-facebook"></i>
                </a>
              </li>
              <li className={`${styles.menuItem}`}>
                <a href="https://twitter.com/dashbotio" className="d-block" target="_blank" rel="noreferrer">
                  <i className="bx bxl-twitter"></i>
                </a>
              </li>
              <li className={`${styles.menuItem}`}>
                <Link
                  href="https://www.linkedin.com/company/dashbot.io/"
                  className="d-block"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bxl-linkedin"></i>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className={`${styles.menu} pt-4 d-none d-md-flex`}>
          <Col md={6}>
            <section>
              <ul className={`list-unstyled`}>
                <li className={`${styles.menuItem}`}>
                  <Link to="https://docs.dashbot.io" className="d-block" target="_blank" rel="noreferrer">
                    Documentation
                  </Link>
                </li>
                <li className={`${styles.menuItem}`}>
                  <Link to="/" className="d-block" target="_blank" rel="noreferrer">
                    Blog
                  </Link>
                </li>
                <li className={`${styles.menuItem}`}>
                  <Link
                    to="https://angel.co/company/dashbot-io/jobs"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers
                  </Link>
                </li>
              </ul>
            </section>
          </Col>
          <Col md={6}>
            <section>
              <ul className={`list-unstyled`}>
                <li className={`${styles.menuItem}`}>
                  <Link to="/legal/tos" className="d-block" target="_blank" rel="noreferrer">
                    Terms of Service
                  </Link>
                </li>
                <li className={`${styles.menuItem}`}>
                  <Link to="/legal/privacy" className="d-block" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </Link>
                </li>
                <li className={`${styles.menuItem}`}>
                  <Link to="https://www.dashbot.com/pricing" className="d-block" target="_blank" rel="noreferrer">
                    Pricing
                  </Link>
                </li>
                <li className={`${styles.menuItem}`}>
                  <Link to="https://www.dashbot.com/contact-us" className="d-block" target="_blank" rel="noreferrer">
                    Contact
                  </Link>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <div className={`${styles.bottomMatter} mt-4 position-relative justify-content-center d-none d-md-block`}>
          <Container>
            <Row className={`py-4`}>
              <Col xs={12} className={`d-inline-flex align-items-center`}>
                <span className={`d-block ${styles.copyright} ml-md-0 mr-4`}>© 2016-{currentYear} Dashbot.io</span>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={`${styles.bottomMatter} mt-4 mt-md-5 position-relative justify-content-center d-block d-md-none`}
        >
          <div className={`${styles.line}`} />
          <Container>
            <Row className={`py-5 py-md-4 px-md-4 justify-content-md-between`}>
              <Col className={`d-inline-flex align-items-center justify-content-center`}>
                <ul className={`align-items-center list-unstyled d-inline-flex`}>
                  <li className={`${styles.menuItem} mt-0 mr-4`}>
                    <Link to={'/legal/tos'}>Terms of Service</Link>
                  </li>
                  <li className={`${styles.menuItem} mt-0 mr-4`}>
                    <Link to={'/legal/privacy'}>Privacy Policy</Link>
                  </li>
                  <li className={`${styles.menuItem} mt-0 mr-4`}>
                    <Link to={`https://www.dashbot.com/pricing`}>Pricing</Link>
                  </li>
                  <li className={`${styles.menuItem} mt-0`}>
                    <Link to={'https://www.dashbot.com/contact-us'}>Contact</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={12}>
                <span className={`d-block ${styles.copyright} ml-md-0 mr-4`}>© 2016-{currentYear} Dashbot.io</span>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
